import * as moment from "moment";
import {DateTimeFormat, MeasurableParameter, OperatingMode} from "./Constants";
import {IDeviceParameter} from "constants/DeviceConstants";
import * as React from "react";

export function GetDateTime(value: Date): string {
    return value ? moment.utc(value).local().format(DateTimeFormat) : null;
}

export function SamplingMap(value: number) {
    switch (value) {
        case 30:
            return "30s";
        case 60:
            return "1min";
        case 300:
            return "5min";
        case 900:
            return "15min";
        case 1800:
            return "30min";
        case 3600:
            return "1h";
        case 43200:
            return "12h";
        case 86400:
            return "24h";
        default:
            return null;
    }
}

export function IsSmogParameter(parameterId: MeasurableParameter) {
    return (parameterId === MeasurableParameter.Pm10 || parameterId === MeasurableParameter.Pm25);
}

export function IsSmogDevice(parameters: IDeviceParameter[]) {
    return (parameters || []).filter(p => p != null && IsSmogParameter(p.parameterId)).length > 0;
}

export function OperationModeMap(value: OperatingMode): string {
    switch (Number(value)) {
        case OperatingMode.Standard:
            return "device.edit.operatingMode.standard";
        case OperatingMode.Control:
            return "device.edit.operatingMode.control";
        default:
            return null;
    }
}

export function getParameterIcon(dp: MeasurableParameter): string {
    if (dp === MeasurableParameter.Pm10 || dp === MeasurableParameter.Pm25)
        return "icon-ipomiar-smog";
    if (dp === MeasurableParameter.Temperature2 || dp === MeasurableParameter.Temperature3)
        return "icon-ipomiar-none";

    if (Object.values(MeasurableParameter).includes(dp))
        return `icon-ipomiar-${MeasurableParameter[dp].toLowerCase()}`;
    return "";
}

export function Coalesce(value: number, alt: number): number {
    return value !== null && !isNaN(value) ? value : alt;
}

export function IsNullOrWhiteSpace(str: string): boolean {
    return (!str || str.length === 0 || /^\s*$/.test(str));
}

export function ValidateNumber(num: any): boolean {
    const maxNumber = 9999999.0;
    let value = Number(num);
    if (!isNaN(value)) {
        return value <= maxNumber && value > -maxNumber;
    }
    return false;
}

export function ToString(value: any): string | null {
    return value !== null ? value.toString() : "";
}

export function GetValueFromControl(control: React.FormEvent<any>): unknown {
    const target = control.target as any;
    return target.type === 'checkbox' ? target.checked : target.value === "" ? null : target.value;
}
