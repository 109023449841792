import { Sampling, OperatingMode, MeasurableParameter, IParameterSettings, AccessMode } from "./Constants";

export interface IEditedDevice {
    name: string;
    deviceId: number;
    parameterSettings: IParameterSettings[];
    sampling: Sampling;
    operatingMode: OperatingMode;
    disconnectedAlert: boolean;
    publicData: boolean;
    latitude: string;
    longitude: string;
}

export interface IDevice {
    name: string;
    deviceId: number;
    isActive: boolean;
}

export interface IDeviceDetails extends IDevice {
    key: string;
    sampling: Sampling;
    previousSampling?: Sampling;
    parameters: IDeviceParameter[];
    latitude: number;
    longitude: number;
    mac: string;
    batteryLevel?: number;
    subscriptionName: string;
    subscriptionLimit: number;
    subscriptionExpirationDate: Date;
    dataTransferExpirationDate: Date;
    operatingMode: OperatingMode;
    previousOperatingMode?: OperatingMode;
    disconnectedAlert: boolean;
    publicData: boolean;
    publicToken: string;
    accessMode: AccessMode;
    smsCounter?: number;
}

export interface IDeviceDataInfo {
    records: IDataRecord[];
    thresholdMax: number;
    thresholdMin: number;
    parameterMaxValue: number;
    parameterMinValue: number;
    parameterId: MeasurableParameter;
    parameterCustomName: string;
    parameterCustomUnit: string;
    deviceId: number;
    deviceName: string;
    totalRecordCount: number;
    isOnline?: boolean;
    accessMode: AccessMode;
}

export interface IDataRecord {
    date: Date;
    value: number;
}

export interface IDeviceDataRecordKey {
    deviceId: number;
    parameterId: number;
    date: Date;
}

export interface IDeviceAction {
    type: string;
    records?: IDataRecord[];
    errorMessage?: string;
}

export interface IDeviceParameter {
    maxValue: number;
    minValue: number;
    thresholdMax?: number;
    thresholdMin?: number;
    customMinValue?: number;
    customMaxValue?: number;
    parameterId: MeasurableParameter;
    customName: string;
    deviceId: number;
    unit: string;
    offset: number;
    customUnit: string;
    converter: number;
    shifter: number;
    showInDashboardTile: boolean;
}

export interface IGaugeParameter extends IDeviceParameter {
    latestValue: number;
}

export class DeviceActions {
    public static readonly FETCH_DEVICES = "FETCH_DEVICES";
    public static readonly FETCH_DEVICES_SUCCESS = "FETCH_DEVICES_SUCCESS";
    public static readonly FETCH_DEVICES_FAILED = "FETCH_DEVICES_FAILED";

    public static readonly FETCH_DEVICE_DETAILS = "FETCH_DEVICE_DETAILS";
    public static readonly FETCH_DEVICE_DETAILS_SUCCESS = "FETCH_DEVICE_DETAILS_SUCCESS";
    public static readonly FETCH_DEVICE_DETAILS_FAILED = "FETCH_DEVICE_DETAILS_FAILED";

    public static readonly SELECTED_DEVICE = "SELECTED_DEVICE";
    public static readonly EDIT_DEVICE = "EDIT_DEVICE";
    public static readonly EDIT_DEVICE_SUCCESS = "EDIT_DEVICE_SUCCESS";
    public static readonly EDIT_DEVICE_FAILED = "EDIT_DEVICE_FAILED";
    public static readonly EDITED_FORM = "EDITED_FORM";
    public static readonly MODE_VIEW = "MODE_VIEW";
    public static readonly CANCEL_EDIT_MODE = "CANCEL_EDIT_MODE";
    public static readonly DELETE = "DELETE_DEVICE";
    public static readonly DELETED = "DELETED_DEVICE";
    public static readonly DELETE_FAILED = "DELETE_DEVICE_FAILED";
    public static readonly SHOW_HIDE_DELETE_DATA_DIALOG = "SHOW_HIDE_DELETE_DEVICE_DATA_DIALOG";
    public static readonly SHOW_HIDE_DELETE_DEVICE_DIALOG = "SHOW_HIDE_DELETE_DEVICE_DIALOG";
};

export class DeviceParameterActions {
    public static readonly NO_DATA = "DP_NO_DATA";
    public static readonly FETCH_DATA = "DP_FETCH_DATA";
    public static readonly FETCH_DATA_SUCCESS = "DP_FETCH_DATA_SUCCESS";
    public static readonly FETCH_DATA_FAILED = "DP_FETCH_DATA_FAILED";
};

export class DeviceDataActions {
    public static readonly FETCH_NO_DATA = "DD_FETCH_NO_DATA";
    public static readonly FETCH_DATA = "DD_FETCH_DATA";
    public static readonly FETCH_DATA_SUCCESS = "DD_FETCH_DATA_SUCCESS";
    public static readonly FETCH_DATA_FAILED = "DD_FETCH_DATA_FAILED";
    public static readonly DATE_CHANGE = "DD_DATE_CHANGE";

    public static readonly CLEAN = "DATA_CLEAN";
    public static readonly CLEANING_DATA = "CLEANING_DATA";
    public static readonly CLEANING_DATA_FAILED = "CLEANING_DATA_FAILED";

    public static readonly ENTRY_REMOVED = "ENTRY_REMOVED";
    public static readonly ENTRY_REMOVING = "ENTRY_REMOVING";
    public static readonly ENTRY_REMOVE_FAILED = "ENTRY_REMOVE_FAILED";

    public static readonly UPDATE_DEVICE_DATA = "DD_UPDATE_DEVICE_DATA";
};