import * as React from "react";
import {CSSProperties, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from 'react-intl';
import '../../less/RegisterDevice.css';
import UserContext, {IUserContext} from '../UserContext';
import {Alert, Button, Modal} from "react-bootstrap";
import {ActivationCodeType, IResult} from "../../constants/Constants";
import SubmitButton from "../SubmitButton";
import ShopLink from "../Device/DeviceDetails/ShopLink";
import "../../less/ActivationCodeModal.css";

interface IActivationCodeModalProps {
    codeType: ActivationCodeType;
    className?: string;
    isVisible: boolean;
    successEvent: () => void;
    submitEvent: (code: string, codeType: ActivationCodeType) => Promise<Response>
}

interface ActivationCodeModalState {
    codeValue: string,
    validationError: string,
    isSuccessed: boolean,
}

const ActivationCodeModal = (props: IActivationCodeModalProps) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    const intl = useIntl();
    const [modelState, setModelState] = useState<ActivationCodeModalState>(
        {
            codeValue: null,
            validationError: null,
            isSuccessed: true
        });
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [successAlertVisible, setSuccessAlertVisible] = useState<boolean>(false);
    let timeoutId: NodeJS.Timeout = null;
    useEffect(() => {
        resetFields();
        return () => {
            resetFields();
        }

    }, []);

    useEffect(() => {
        if (successAlertVisible) {
            timeoutId = setTimeout(function () {
                setSuccessAlertVisible(false);
            }, 3000);
        }
        return () => {
            clearTimeout(timeoutId);
        }       
    }, [successAlertVisible]);

    const resetFields = () => setModelState({codeValue: null, validationError: null, isSuccessed: true});
    const handleInputChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = target.value;
        const isValidated = isValidCode(value);

        setModelState({
            codeValue: value,
            validationError: isValidated ? null : "device.activation-code.format.error",
            isSuccessed: isValidated
        });
    }

    const closeDialog = () => {
        resetFields();
        setModalVisible(false);
    }
    const openDialog = () => setModalVisible(true);

    const isValidCode = (value: string) => {
        const key = (value || "");
        return isValidGUID(key);
    }

    const isValidGUID = (value: string): boolean => {
        if (value.length > 0) {
            if ((/^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/).test(value)) {
                return true;
            }
        }
        return false;
    }

    const icon = () => {
        switch (props.codeType) {
            case ActivationCodeType.PremiumForever:
                return (
                    <div
                        className="icon-ipomiar-premium"
                        style={{
                            display: "inline-block",
                            position: "relative",
                            top: "3px",
                            fontSize: "18px"
                        }}/>);
            case ActivationCodeType.Sms100:
                return (<i className="material-icons">notification_add</i>);
            case ActivationCodeType.DataTransfer:
                return (<i className="material-icons">cell_tower</i>);
            default:
                return null;
        }
    }

    const submit = () => {
        props.submitEvent(modelState.codeValue, props.codeType)
            .then((response: Response) => {
                if (!response.ok) {
                    setModelState({
                        ...modelState,
                        isSuccessed: false,
                        validationError: "device.activation-code.errors.process.error"
                    });
                    return Promise.reject(response.statusText);
                }
                return response;
            })
            .then((response: Response) => response.json())
            .then((result: IResult) => {
                setModelState({...modelState, isSuccessed: result.isSuccessed, validationError: result.message});
                if (result.isSuccessed) {
                    props.successEvent();
                    closeDialog();
                    setSuccessAlertVisible(true);
                }
            });
    }

    //Not supported for DCX
    if (userContext.applicationName === "dcx")
        return null;

    if (!props.isVisible)
        return null;

    const alertStyle: CSSProperties = {width: "auto", position: "fixed", zIndex: "999", left: "50%", top: "30px"};
    return (
        <>
            <div className={`${props.className}`}
                 title={intl.formatMessage(
                     {id: `device.activation-code.${ActivationCodeType[props.codeType].toLowerCase()}.title`}
                 )}>
                <Button size="sm" onClick={openDialog}>
                    {icon()}
                    <FormattedMessage
                        id={`device.activation-code.${ActivationCodeType[props.codeType].toLowerCase()}.title`}/>
                </Button>
                <div className="description">
                    <FormattedMessage
                        id={`device.activation-code.${ActivationCodeType[props.codeType].toLowerCase()}.desc`}/>
                </div>
            </div>

            {successAlertVisible
                ? (<Alert variant="success" style={alertStyle}>
                    <FormattedMessage id="device.activation-code.activated.desc"/>
                </Alert>)
                : null}
            <Modal centered show={modalVisible} onHide={closeDialog}
                   className={"activation-code-modal " + userContext.style}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage
                            id={`device.activation-code.${ActivationCodeType[props.codeType].toLowerCase()}.title`}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="control-label">
                            <FormattedMessage id="device.activation-code.label"/>
                        </label>
                        <input
                            className={"form-control" + (!modelState.isSuccessed ? " error" : "")}
                            type="text"
                            name="key"
                            placeholder="00000000-0000-0000-0000-000000000000"
                            defaultValue={modelState.codeValue}
                            onChange={handleInputChange}/>
                        {!modelState.isSuccessed && modelState.validationError != null ?
                            <span className="code-error"><FormattedMessage id={modelState.validationError}/></span>
                            : null
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{width: "75%", height: "20px", float: "left"}}>
                        <ShopLink type={props.codeType} showDescription={true}/>
                    </div>
                    <SubmitButton onClick={submit} disabled={!modelState.isSuccessed}>
                        <FormattedMessage id="device.edit.activation-code.use"/>
                    </SubmitButton>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ActivationCodeModal;