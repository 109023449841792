import * as React from "react";
import {Col, FormGroup, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {SamplingMap} from "constants/Common";
import {IDeviceDetails, IEditedDevice} from "constants/DeviceConstants";
import Radio from "../../../controles/Radio";
import ChangeStateSign from "./ChangeStateSign";


const DeviceDetailsSamplingRadio = (
    props: {
        editForm: IEditedDevice,
        device: IDeviceDetails
        handleInputChange: (event: React.FormEvent<any>) => void
    }
) => {
    let samplingOptions = [60, 300, 900, 1800, 3600, 43200, 86400];
    const deprecatedSampling = 30;
    let sampling = Number(props.editForm.sampling);  
    if(sampling == deprecatedSampling)
        samplingOptions.unshift(deprecatedSampling);
    return (<FormGroup controlId="formHorizontalSampling" className="sampling">
        <Row>
            <Col className="label">
                <FormattedMessage id="device.edit.sampling"/>
                <ChangeStateSign
                    hide={(props.device.previousSampling === null
                            && props.device.sampling === sampling)
                        || props.device.previousSampling === sampling}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                {
                    samplingOptions.map(v =>
                        <Radio name="sampling" key={`radio_${v}`} value={v}
                               checked={sampling === v}
                               disabled={v == deprecatedSampling}
                               onChange={props.handleInputChange}>
                            {SamplingMap(v)}
                        </Radio>
                    )
                }
            </Col>
        </Row>
    </FormGroup>)
}

export default DeviceDetailsSamplingRadio;